<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h1>Nalozi za knjiženje</h1>
            </b-col>
            <b-col cols="6" class="print-show">
                <mara-table :item="selectedClient"></mara-table>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/entry_orders/create">
                    <b-btn variant="primary">Novi nalog za knjiženje</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="entryOrders">
            <b-col>
                <osta-table position
                            search-key="description"
                            :items="entryOrders"
                            :fields="entryOrdersFields"
                            @rowClicked="goToEntryOrder">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'entryOrders',
        data() {
            return {
                entryOrders: null,
                entryOrdersFields: [
                    {
                        key: 'document_number',
                        label: 'Broj dokumenta',
                        sortable: true
                    },
                    {
                        key: 'description',
                        label: 'Opis',
                        sortable: true
                    },
                    {
                        key: 'document_date',
                        label: 'Datum dokumenta',
                        sortable: true,
                        formatter: (value) => this.$moment(value).format("DD.MM.YYYY.")
                    },
                    {
                        key: 'type',
                        label: 'Tip',
                        sortable: true,
                        formatter: (value) => (value == 0) ? 'Kupci/Dobavljači' : 'Banka - Blagajna'
                    },
                    {
                        key: 'confirmed',
                        label: "Potvrđen",
                        sortable: true,
                        formatter: (value) => value ? 'DA' : 'NE'
                    }
                ],
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToEntryOrder(id) {
                this.$router.push('/entry_orders/' + id);
            },
            getEntryOrders() {
                axiOsta.post(api.clients + 'entry_orders', {
                    client_id: this.$parent.selectedClient.id,
                    year: this.$cookie.get('ezint-year')
                }).then(response => {
                    if (response.status === 200) {
                        this.entryOrders = response.data;
                    }
                })
            }
        },
        created() {
            if (this.selectedClient != null) {
                this.getEntryOrders();
            }
            eventBus.$on('year-changed', () => {
                this.getEntryOrders();
            });
        },
        watch: {
            selectedClient: function (newValue, oldValue) {
                if (oldValue == null) {
                    this.getEntryOrders();
                }
            },
        }
    }
</script>
